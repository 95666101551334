import React from 'react'
import {Create, TextInput, SimpleForm, BooleanInput, useTranslate} from 'react-admin'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  plane: {
    width: '100%',
    maxWidth: 800,
  },
}))

export const RouteCreate = props => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <>
      <Create {...props}>
        <SimpleForm initialValues={{name: '_', last_places: []}}>
          <Box className={classes.plane}>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth source="name_ru" label={translate('resources.places_bot_plugin/route.fields.name_ru', 1)}/>
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth source="name_en" label={translate('resources.places_bot_plugin/route.fields.name_en', 1)}/>
              </Box>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="description_ru" label={translate('resources.places_bot_plugin/route.fields.description_ru', 1)}/>
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="description_en" label={translate('resources.places_bot_plugin/route.fields.description_en', 1)}/>
              </Box>
            </Box>

            <Box className={classes.plane} mx={1}>
              <BooleanInput source="can_choose_start_place" label={translate('resources.places_bot_plugin/route.fields.can_choose_start_place', 1)}/>
              <BooleanInput source="is_one_way_mode"  label={translate('resources.places_bot_plugin/route.fields.is_one_way_mode', 1)}/>
            </Box>
          </Box>
        </SimpleForm>
      </Create>
    </>
  )
}
